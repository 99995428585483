var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_list_of_product") } },
    [
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-form-model",
            {
              ref: "theForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-col": { span: 3 },
                "wrapper-col": { span: 14 }
              },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submitForm.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRules.branch.label),
                    prop: "branchId"
                  }
                },
                [
                  _c("CSelectBranch", {
                    on: { "on-change": _vm.onChangeBranch },
                    model: {
                      value: _vm.form.branchId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "branchId", $$v)
                      },
                      expression: "form.branchId"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRules.warehouse.label),
                    prop: "branchWarehouseId"
                  }
                },
                [
                  _c("CSelectWarehouse", {
                    attrs: { "by-branch": _vm.form.branchId },
                    on: { "on-change": _vm.onChangeWarehouse },
                    model: {
                      value: _vm.form.branchWarehouseId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "branchWarehouseId", $$v)
                      },
                      expression: "form.branchWarehouseId"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRules.rack.label),
                    prop: "locationId"
                  }
                },
                [
                  _c("CSelectRack", {
                    attrs: { "by-warehouse": _vm.form.branchWarehouseId },
                    model: {
                      value: _vm.form.locationId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "locationId", $$v)
                      },
                      expression: "form.locationId"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRules.product.label),
                    prop: "productId"
                  }
                },
                [
                  _c("CSelectProduct", {
                    attrs: { "filter-active": false },
                    on: { "on-select": function() {} },
                    model: {
                      value: _vm.form.productId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "productId", $$v)
                      },
                      expression: "form.productId"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { "wrapper-col": { span: 14, offset: 3 } } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleClear } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_clear")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            icon: "search",
                            type: "primary",
                            loading: _vm.loading.submit
                          },
                          on: { click: _vm.submitForm }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c("a-col", { attrs: { span: 12 } }, [
                _c(
                  "h4",
                  {},
                  [
                    _c("a-icon", { attrs: { type: "calendar" } }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("lbl_as_of_date")) +
                        ": " +
                        _vm._s(_vm._f("date")(_vm.today)) +
                        " "
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("TableCustom", {
                    attrs: {
                      idtable: "table1",
                      "on-select-change": null,
                      "selected-row-keys": null,
                      "data-source": _vm.dataListInventory.data,
                      columns: _vm.columnsTable,
                      scroll: { x: "calc(800px + 50%)", y: 520 },
                      paginationcustom: true,
                      "default-pagination": false,
                      loading: _vm.loading.table
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("Pagination", {
                    attrs: {
                      total: _vm.dataListInventory.totalElements,
                      "page-size-set": _vm.limit,
                      "id-pagination": "pagination1"
                    },
                    on: {
                      "response-pagesize-change": _vm.responsePageSizeChange,
                      "response-currentpage-change":
                        _vm.responseCurrentPageChange
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12, align: "end" } },
                [
                  _c("a-tag", { attrs: { color: "#8c8c8c" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("lbl_total_found")) +
                        ": " +
                        _vm._s(_vm.totalElements || 0) +
                        " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex", justify: "end" } },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "printer",
                            loading: _vm.loading.print
                          },
                          on: { click: _vm.handlePrint }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "download",
                            loading: _vm.loading.download
                          },
                          on: { click: _vm.handleDownload }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }