































































































































































import Vue from "vue";
import moment from "moment";
import { ResponsePagination } from "@/models/interface/common.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponseListInventoryLineBatch } from "@interface/inventory-line-batch.interface";
import { inventoryLineBatchService } from "@service/inventory-line-batch.service";
import { formatDecimalQty } from "@/helpers/common";
import { DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";
import { printPDF } from "@helper/print-js";
import MNotificationVue from "@/mixins/MNotification.vue";
import { downloadFile } from "@/helpers/file-reader";
export default Vue.extend({
  name: "ListOfProduct",
  components: {
    CSelectWarehouse: () => import("@/components/shared/select-warehouse/CSelectWarehouse.vue"),
    CSelectProduct: () => import("@/components/shared/select-master-product/CSelectMasterProduct.vue"),
  },
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      totalElements: 0 as number,
      page: 0 as number,
      limit: DEFAULT_PAGE_SIZE as number,
      search: "" as string,
      columnsTable: [
        {
          title: this.$t("lbl_location"),
          dataIndex: "warehouseLocationName",
          key: "branch",
          sorter: false,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_product"),
          dataIndex: "productName",
          key: "productName",
          sorter: false,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_uom"),
          dataIndex: "uom",
          key: "uom",
          sorter: false,
          width: 90,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_batch_number"),
          dataIndex: "batchNumber",
          key: "batchNumber",
          sorter: false,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_safety_stock"),
          dataIndex: "safetyStock",
          key: "safetyStock",
          sorter: false,
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_on_order"),
          dataIndex: "onOrder",
          key: "onOrder",
          sorter: false,
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_on_hand_available_reserved"),
          dataIndex: "onHand",
          key: "onHand",
          sorter: false,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_available"),
          dataIndex: "available",
          key: "available",
          sorter: false,
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_reserved"),
          dataIndex: "reserved",
          key: "reserved",
          sorter: false,
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_on_transit"),
          dataIndex: "onTransit",
          key: "onTransit",
          sorter: false,
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
      ] as any,
      form: {
        branchId: undefined as string | undefined,
        branchWarehouseId: undefined as string | undefined,
        locationId: undefined as string | undefined,
        productId: undefined as string | undefined,
      },
      rules: {
        branchId: [{ required: false }],
        branchWarehouseId: [{ required: false }],
        locationId: [{ required: false }],
        productId: [{ required: false }],
      },
      dataListInventory: {} as ResponseListInventoryLineBatch,
      formRules: {
        branch: {
          label: "lbl_branch",
          name: "branch",
          placeholder: "lbl_choose",
        },
        warehouse: {
          label: "lbl_warehouse",
          name: "warehouse",
          placeholder: "lbl_choose",
        },
        rack: {
          label: "lbl_rack",
          name: "rack",
          placeholder: "lbl_choose",
        },
        product: {
          label: "lbl_product",
          name: "product",
          placeholder: "lbl_choose",
        },
      },
      loading: {
        download: false,
        print: false,
        table: false,
        submit: false,
      }
    };
  },
  computed: {
    today(): string {
      return this.moment().format();
    },
  },
  created() {
    this.getListOfInventory();
  },
  methods: {
    onChangeBranch(): void {
      this.form.branchWarehouseId = undefined;
      this.form.locationId = undefined;
    },
    onChangeWarehouse(): void {
      this.form.locationId = undefined;
    },
    handleClear(): void {
      this.form = {
        branchId: undefined,
        branchWarehouseId: undefined,
        locationId: undefined,
        productId: undefined,
      };
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListOfInventory();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page - 1;
      this.getListOfInventory();
    },
    moment,
    submitForm(): void {
      const searchBy: string[] = [];
      const { branchId, branchWarehouseId, locationId, productId } = this.form;
      if (branchId) {
        searchBy.push(`warehouseLocation.warehouse.branchWarehouse.secureId~${branchId}`);
      }
      if (branchWarehouseId) {
        searchBy.push(`warehouseLocation.warehouse.secureId~${branchWarehouseId}`);
      }
      if (locationId) {
        searchBy.push(`warehouseLocation.secureId~${locationId}`);
      }
      if (productId) {
        searchBy.push(`product.secureId~${productId}`);
      }
      if (!searchBy.length) return;
      this.search = searchBy.join("_AND_");
      this.page = 0;
      this.loading.submit = true;
      this.getListOfInventory();
    },
    buildDownloadParams() {
      const searchBy: string[] = [];
      const { branchId, branchWarehouseId, locationId, productId } = this.form;
      if (branchId) {
        searchBy.push(`branchId~${branchId}`);
      }
      if (branchWarehouseId) {
        searchBy.push(`warehouseId~${branchWarehouseId}`);
      }
      if (locationId) {
        searchBy.push(`locationId~${locationId}`);
      }
      if (productId) {
        searchBy.push(`productId~${productId}`);
      }
      return searchBy.join("_AND_");
    },
    async handleDownload(): Promise<void> {
      try {
        this.loading.download = true;
        const params = {
          search: this.buildDownloadParams(),
        };
        const blob = await inventoryLineBatchService.download(params);
        downloadFile(new Blob([blob]), `list_of_product_${this.today}.xlsx`);
      } catch (error) {
        this.showNotifError("notif_download_error");
      } finally {
        this.loading.download = false;
      }
    },
    async handlePrint(): Promise<void> {
      try {
        this.loading.print = true;
        const params = {
          search: this.buildDownloadParams(),
        };
        const blob = await inventoryLineBatchService.print(params);
        printPDF(new Blob([blob]), () => {
          this.showNotifError("error_print_fail");
        });
      } catch (error) {
        this.showNotifError("error_print_fail");
      } finally {
        this.loading.print = false;
      }
    },
    getListOfInventory(): void {
      let params = {
        limit: this.limit,
        page: this.page,
      } as RequestQueryParamsModel;
      if (this.search) params.search = this.search;
      this.loading.table = true;

      inventoryLineBatchService
        .getListInventoryLineBatch(params)
        .then((res: any) => {
          res.data.forEach(
            (dataObject, index) => {
              dataObject.key = index;
              dataObject.productName = dataObject.product.name;
              dataObject.uom = dataObject.uom.unit;
              dataObject.safetyStock = formatDecimalQty(dataObject.product.minimumStock);
              dataObject.onOrder = formatDecimalQty(dataObject.onOrder);
              dataObject.onHand = formatDecimalQty(dataObject.onHand);
              dataObject.available = formatDecimalQty(dataObject.available);
              dataObject.reserved = formatDecimalQty(dataObject.reserved);
              dataObject.onTransit = formatDecimalQty(dataObject.onTransit);
            }
          );
          this.dataListInventory = res;
          this.totalElements = res.totalElements;
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.loading.table = false;
          this.loading.submit = false;
        });
    },
  },
});
